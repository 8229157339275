import { default as login0fe4OijFFWMeta } from "/codebuild/output/src4183946781/src/observer-ui/pages/auth/login.vue?macro=true";
import { default as signuphzoSbuJuvvMeta } from "/codebuild/output/src4183946781/src/observer-ui/pages/auth/signup.vue?macro=true";
import { default as indexZPwqrhjR9HMeta } from "/codebuild/output/src4183946781/src/observer-ui/pages/index.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login0fe4OijFFWMeta || {},
    component: () => import("/codebuild/output/src4183946781/src/observer-ui/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signuphzoSbuJuvvMeta || {},
    component: () => import("/codebuild/output/src4183946781/src/observer-ui/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src4183946781/src/observer-ui/pages/index.vue").then(m => m.default || m)
  }
]
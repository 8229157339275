import validate from "/codebuild/output/src4183946781/src/observer-ui/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/codebuild/output/src4183946781/src/observer-ui/middleware/auth.global.ts";
import manifest_45route_45rule from "/codebuild/output/src4183946781/src/observer-ui/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/codebuild/output/src4183946781/src/observer-ui/node_modules/vue-clerk/dist/nuxt/runtime/middleware/auth.js"),
  guest: () => import("/codebuild/output/src4183946781/src/observer-ui/node_modules/vue-clerk/dist/nuxt/runtime/middleware/guest.js")
}